import React, { useState, useEffect } from "react";
// import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { useParams } from "react-router-dom";

////LOCAL URI:  http://spx-nlb-esaleserialsearch-dev-05-80adade04a83b046.elb.us-east-2.amazonaws.com:8000/

function SerialData() {
  const [serialValue, setSerialValue] = useState([]);
  const { userId, equipId } = useParams();
  const [bomValue, setBomValue] = useState([]);
  const [bomTypeCodeIndex, setBomTypeCodeIndex] = useState(0);
  const [partloading, setPartLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  console.log(bomValue, "bomvalue");


  //  equipInfo
  useEffect(() => {
    const jsonBody = JSON.stringify({ userId: userId, equipId: equipId });
    console.log("EquipmentId", jsonBody);
    try {
      console.log("trying to fetch");
      fetch(process.env.REACT_APP_API  + "/equipmentinfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: jsonBody,
        }
      )
        .then((response) => {
          console.log("what happened in here?");
          if (response.status === 404) {
            throw new Error("Not Found");
          }
          return response.json();
        })
        .then((data) => {
          console.log("data", data);
          setSerialValue(data, setLoading(false));
        })
        .catch((error) => {
          if (error.message === "Not Found") {
            console.log("404 error: Response not found");
          } else {
            console.log(error);
          }
        });
      console.log("probably failing to fetch");
    } catch (e) {
      console.log("Error!", e);
    } 
  }, [userId, equipId]);

  // bominfo
  useEffect(() => {
    if (
      serialValue.data !== undefined &&
      serialValue.data.status === "Success" &&
      serialValue.data.bomTypeAvail.length > 0
    ) {
      setPartLoading(true);
      console.log(serialValue.data.bomTypeAvail, "bom");
      const typeCode = serialValue.data.bomTypeAvail[bomTypeCodeIndex].typeCode;
      console.log("Calling bominfo", equipId, typeCode);
      fetch(process.env.REACT_APP_API  +"/bominfo?equipId=" + equipId + "&typeCode=" + typeCode,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          console.log("got response BOM", response.headers.get("content-type"));
          return response.json();
        })
        .then((data) => {
          console.log("setting BOM Data", data);
          setBomValue(data);
        })
        .catch((error) => console.log(error))
        .finally(() => setPartLoading(false));
    }
  }, [serialValue, bomTypeCodeIndex]);

  const result = serialValue?.mappings?.map((e) =>
    e.listItems
      ?.map((item) => {
        const key = item.selectValue;
        //const value = JSON.parse(serialValue?.data ?? '[]')[key];
        const value = serialValue.data[key];
        return { title: item.displayText, value };
      })
      .filter((item) => item.title !== "" && item.value !== "")
  );
  console.log(loading, "loading");
  console.log("BOM Data", bomValue);
  return (
    <>
      <div className="main-container">
        {loading ? (
          <h1>Loading...</h1>
        ) : (
          <>
            <div className="parentcontainer">
              <div className="leftcard">
                <ul>
                  <div className="serialnumber">
                    <h3>Serial # {serialValue?.data?.sn}</h3>
                  </div>
                  <div className="serialtext">
                    <h5>
                      Part Number: <span>{serialValue?.data?.partNumber}</span>{" "}
                    </h5>
                    <h5>
                      Brand : <span>{serialValue?.data?.brand}</span>
                    </h5>
                    <h5>
                      Description : <span>{serialValue?.data?.description}</span>
                    </h5>{" "}
                  </div>
                  {result?.map((e) =>
                    e.map((ele, index) => {
                      if (ele.value) {
                        return (
                          <div key={index} className="titles">
                            <h5>
                              {ele?.title} : <span>{ele?.value}</span>
                            </h5>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                  )}
                </ul>
              </div>
              {/* <div className="rightcard">
            <table className="table-with-gray-lines">
              <tbody>
                <tr className="table-row-with-gray-line">
                  <td className="tableheader">Characteristic Name</td>
                  <td className="tableheader">Value</td>
                </tr>
                <tr className="table-row-with-gray-line">
                  <td className="table-cell">
                    <a href="">Motor</a>
                  </td>
                  <td className="table-cell">ICE Motor</td>
                </tr>
                <tr className="table-row-with-gray-line">
                  <td className="table-cell">
                    <a href="">Impeller meter</a>
                  </td>
                  <td className="table-cell">120.0</td>
                </tr>
                <tr className="table-row-with-gray-line">
                  <td className="table-cell">
                    <a href="">Mount</a>
                  </td>
                  <td className="table-cell">
                    150# Flange{" "}
                    <span className="trash-icon" style={{ fontSize: "22px" }}>
                      {" "}
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        onClick={() => {
                          alert("id");
                        }}
                      />
                    </span>{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
            </div>

            <div className="tab-head">
              <div className="container my-5 mx-0">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {serialValue?.data?.bomTypeAvail?.map((bomType, index) => (
                    <li className="nav-item" role="presentation" key={index}>
                      <button
                        className={`nav-link ${
                          index === bomTypeCodeIndex ? "active" : ""
                        }`}
                        id={`tab-${index}-tab`}
                        data-bs-toggle="tab"
                        data-bs-target={`#tab-${index}-pane`}
                        type="button"
                        role="tab"
                        onClick={() => {
                          if (index !== bomTypeCodeIndex) {
                            setBomValue([]);
                            (() => {
                              console.log("TPI:", index);
                              setBomTypeCodeIndex(index);
                              setPartLoading(true);
                            })();
                          }
                        }}
                        aria-controls={`tab-${index}-pane`}
                        aria-selected={index === 0 ? "true" : "false"}
                      >
                        {bomType?.typeDescription}
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabIndex="0"
                  >
                    {partloading ? (
                      <h5>Loading...</h5>
                    ) : bomValue?.data === undefined ||
                      bomValue?.data?.status !== "Success" ? (
                      <div>
                        <h2>"No Part Lists Found"</h2>
                      </div>
                    ) : (
                      <table className="build-data">
                        <thead>
                          <tr className="build-row-header">
                            <td className="build-table-header">Item Number</td>
                            <td className="build-table-header">
                              Material Number
                            </td>
                            <td className="build-table-header">Description</td>
                            <td className="build-table-header">Quantity</td>
                            <td className="build-table-header">UOM</td>
                          </tr>
                        </thead>
                        <tbody>
                          {bomValue?.data?.bomItems?.map((item, index) => {
                            return (
                              <tr key={index} className="build-row-data">
                                <td className="build-table-cell">
                                  {item?.componentItemNo}
                                </td>
                                <td className="build-table-cell">
                                  {item?.componentMatNo}
                                </td>
                                <td className="build-table-cell">
                                  {item?.componentDesc}
                                </td>
                                <td className="build-table-cell">
                                  {item?.quantity}
                                </td>
                                <td className="build-table-cell">
                                  {item?.uom}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="footer"></div>
      </div>
    </>
  );
}

export default SerialData;
