import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Search from '../components/search';
import SerialSearch from '../components/serialSearch';
import Header from "../components/header";
import SerialData from '../components/serialData';
// import Fetcher from './fetcher';

function AppRouting() {
  return (
    <>
    <Header/>
      <Routes>
        {/* <Route exact path="/" element={<Fetcher/>} /> */}
        <Route exact path="/" element={<Search/>} />
        <Route path="/serialSearch/:searchInput" element={<SerialSearch/>} />
        <Route path="/serialData/:userId/:equipId" element={<SerialData/>} />
      </Routes>
    </>
  );
}

export default AppRouting;