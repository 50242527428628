import React from "react";
import "../App.css";
import logo from "../assets/logo-tagline.png";
import "bootstrap/dist/css/bootstrap.min.css";

function Header() {
  return (
    <div className="main-header">
    <div className="header d-flex flex-column flex-md-row align-items-center">
      <div className="img me-md-3">
        <img src={logo} title="Logo" alt="" />
      </div>
      <div className="navtext mt-3 mt-md-0">
        <div>SerialNumber Data</div>
      </div>
    </div>
  </div>
  
  );
}

export default Header;
