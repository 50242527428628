import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

function Search() {
  const [searchInput, setSearchInput] = useState();

  const navigate = useNavigate();

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchInput !== "") {
      navigate(`/serialSearch/${searchInput}`);
    }
  };




  return (
    <div className="container my-4">
    <div className="row justify-content-center align-items-center">
      <div className="col-12 col-md-6">
        <form className="d-flex" onSubmit={handleSubmit}>
          <input
            className="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={searchInput}
            onChange={handleChange}
          />
          <button className="btn btn-outline-success" type="submit">Search</button>
        </form>
      </div>
    </div>
  </div>
  
  );
}

export default Search;
