import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

function SerialSearch() {
  const [data, setData] = useState([]);
  const { searchInput } = useParams();
  const navigate = useNavigate();

  console.log(data,"serialdata");

  
  function seriallookupData(){
    console.log('looking up serial data',searchInput)
    fetch(process.env.REACT_APP_API + "/seriallookup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sn: searchInput }),
    })
    .then((response) => {
      if (response.status === 404) {
        throw new Error("Not Found");
      }
      return response.json();
    })
    .then((data) => setData(data))
    .catch((error) => {
      if (error.message === "Not Found") {
        console.log("404 error: Response not found");
      } else {
        console.log(error);
      }
    });
  }
    useEffect(() => {
      seriallookupData()
    }, [searchInput]);

  return (
    <div className="main-table">

      {data.length === 0 && <h2>Loading...</h2>}
      {data.length > 0 && data[0]?.status === "Serial Number not found." && (
  
          <div>
            <h4 className="search-text">
              No results found for the term : "{searchInput}"
            </h4>
          </div>
      )}
      {data?.length > 0 && data?.length < 10 && data[0]?.status === "Success" && (
        <div className="search-main-table">
          <h4 className="search-text">
            {data?.length === 1 &&
              navigate(
                `/serialData/${"itsme@gmail.com"}/${data[0]?.equipId}`
              )}
          </h4>
          <div className="muliple-equip">
          <h4>
            Multiple Equipment records have been found for the term:
            {searchInput}
          </h4>
          </div>
          <div className="search-table">
            <table className="build-data">
              <thead>
                <tr className="build-row-header">
                  <td className="build-table-header">Serial Number</td>
                  <td className="build-table-header">Material Number</td>
                  <td className="build-table-header">Description</td>
                  <td className="build-table-header">Brand</td>
                </tr>
              </thead>
              <tbody>
                {data?.length < 10 && data?.map((item, index) => {
                  return (
                    <tr key={index} className="build-row-data">
                      <td className="build-table-cell">
                        <Link
                          className="serial-link"
                          to={`/serialData/${"itsme@gmail.com"}/${
                            item?.equipId
                          }`}>
                          {item?.sn}
                        </Link>
                      </td>
                      <td className="build-table-cell">{item?.partNumber}</td>
                      <td className="build-table-cell">{item?.description}</td>
                      <td className="build-table-cell">{item?.brand}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      { data[0]?.status === "Too many results for role" && (
        <div>
          <div className="search-text-header">
            <h4 className="search-text">Too Many results </h4>
          </div>
          <div className="search-text-head">
            <h5>
              Too many results were found. Please refine your search or contact
              us to assist in finding the equipment
            </h5>
          </div>
        </div>
      
      )}
    </div>
  );
}

export default SerialSearch;
